














/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";

import { AuditMetadataClient } from "@/types/Audit";

import { api } from "@/store/modules/audit";
import { api as appApi } from "@/store/modules/app";
import { api as auditResultApi } from "@/store/modules/auditResult";
import { api as configApi } from "@/store/modules/configuration";

import AReportRequestConfigurationDialog from "@/components/dialogs/AReportRequestConfigurationDialog.vue";

import AAuditResultWidget from "@/components/widgets/AAuditResultWidget.vue";

import {
  WorkflowActionPayload,
  WorkflowActionIds,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";

// Export
import moment from "moment";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { createError } from "../utils/Errors";
import { DIALOG_NAMES, dialogRoute } from "@/routenames";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import requestPdfReport, { PdfReportRequest } from "@/utils/requestPdfReport";
import { contentLanguage } from "@/plugins/ContentTranslation";
import {
  ExcelExportConfig,
  ReportPageConfig,
} from "@auditcloud/shared/lib/schemas";
import { AuditReportExportData } from "@auditcloud/shared/lib/types/AuditReportExport";
import { TodoMap } from "@auditcloud/shared/lib/utils/type-guards";
import { cloneDeep } from "lodash";
import {
  ExcelMetadataCollection,
  FlatReportData,
} from "@/store/modules/audit/getters";
import generateXlsxReport from "@/utils/generateXlsxReport";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";

@Component({
  components: {
    AAuditResultWidget,
    AReportRequestConfigurationDialog,
  },
})
export default class AAuditCompleted extends Vue {
  isDownloadDialogOpen: boolean = false;
  pdfRequest: PdfReportRequest | null = null;

  get auditName() {
    return this.auditMetadata ? this.auditMetadata.name : "";
  }

  get filenamePdf() {
    return `Report_${moment().format("YYYY-MM-DD")}_${formatAuditName(
      this.auditMetadata
    )}.pdf`;
  }

  get filenameXlsx() {
    return `Report_${moment().format("YYYY-MM-DD")}_${formatAuditName(
      this.auditMetadata
    )}.xlsx`;
  }

  @Getter(configApi.getters.audit, { namespace: configApi.namespace })
  audit!: TodoMap;

  openReportingDialog() {
    const params: any = this.$router.currentRoute.params;
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.REPORTING_DIALOG),
      params: {
        auditId,
      },
    });
  }

  @Getter(auditResultApi.getters.getAuditReportExportData, {
    namespace: auditResultApi.namespace,
  })
  exportData!: AuditReportExportData;

  get generatePdf() {
    console.log("generatePdf", this.pdfRequest);
    if (this.pdfRequest === null) {
      return () => Promise.reject(createError("Request Data is null"));
    }
    if (this.pdfRequest.pages.length === 0) {
      return () => Promise.reject(createError("No report pages configured"));
    }
    return requestPdfReport(this.pdfRequest);
  }

  get actionPlanExcelExportConfig(): ExcelExportConfig {
    if (this.auditClass !== null && this.auditClass.actionListExportConfig) {
      return this.auditClass.actionListExportConfig;
    } else {
      return {
        templateName: this.audit.exportTemplate,
        contentListSheetName: this.audit.exportSheetName || "Pruefungsbericht",
        reportSheetName: this.audit.exportReportSheetName || "Report",
        contentListConfig: {
          offset: this.audit.exportSheetOffset || 6,
          columNames: this.audit.exportSheetCols || [],
        },
      };
    }
  }

  get next() {
    return {
      name: "wrapup",
      params: {
        auditId: this.auditId,
      },
    };
  }

  get prev() {
    return {
      name: "wrapup",
      params: {
        auditId: this.auditId,
      },
    };
  }

  @Getter(api.getters.getReportPages, { namespace: api.namespace })
  reportPages!: ReportPageConfig[];

  @Getter(configApi.getters.configurableReportFeatureEnabled, {
    namespace: configApi.namespace,
  })
  configurableReportFeatureEnabled!: boolean;

  //missingDefault
  @Prop({
    type: String,
  })
  readonly auditId!: string;

  isIntersecting: boolean = false;
  offsetTop: number = 0;

  onScroll(e: any) {
    if (window.pageYOffset > 70) {
      this.isIntersecting = true;
    } else {
      this.isIntersecting = false;
    }
  }

  @Getter(api.getters.getAuditMetadata, { namespace: api.namespace })
  auditMetadata!: AuditMetadataClient | null;

  @Getter(api.getters.getAuditClass, { namespace: api.namespace })
  auditClass!: AuditClassClient | null;

  @Getter(api.getters.getActionsPerView, { namespace: api.namespace })
  actionsPerView!: (viewname: string) => string[];

  @Getter(api.getters.getExcelMetadataCollection, {
    namespace: api.namespace,
  })
  excelMetadataCollection!: ExcelMetadataCollection;

  @Getter(api.getters.getFlatReportData, {
    namespace: api.namespace,
  })
  flatFindingsList!: FlatReportData;

  downloadBlob: null | Blob = null;
  blobUrl: nullable<string> = null;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  setLoading!: (v: boolean) => Promise<any>;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  addStatus!: (payload: { message: string; type: "error" }) => Promise<any>;

  //  Export Excel
  downloadXlsx(useLocalTemplate: boolean) {
    const self = this;

    generateXlsxReport(
      this.excelMetadataCollection,
      this.flatFindingsList,
      this.actionPlanExcelExportConfig,
      useLocalTemplate,
      this.ContentTranslation.language
    )()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        self.downloadBlob = blob;
        self.blobUrl = url;

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = self.blobUrl;
        a.download = self.filenameXlsx;
        a.click();
        document.body.removeChild(a);
      })
      .catch(err => {
        console.log("Download failed", err);
      });
  }

  triggerDownloadPdf() {
    if (this.exportData === null) {
      return () => Promise.reject(createError("Report export data is null"));
    }
    this.pdfRequest = {
      templateName: "report",
      language: contentLanguage(),
      reportData: this.exportData,
      pages: cloneDeep(this.reportPages),
    };
    if (this.configurableReportFeatureEnabled) {
      this.isDownloadDialogOpen = true;
    } else {
      this.downloadPdf();
    }
  }

  downloadPdf() {
    this.isDownloadDialogOpen = false;
    this.setLoading(true);
    const self = this;
    const pdfGenerator = this.generatePdf;

    pdfGenerator()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        self.downloadBlob = blob;
        self.blobUrl = url;

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = self.blobUrl;
        a.download = self.filenamePdf;
        a.click();
        document.body.removeChild(a);
        self.setLoading(false);
      })
      .catch(err => {
        console.log("Download failed", err);
        self.addStatus({ message: "Download failed", type: "error" });
        self.setLoading(false);
      });
  }

  mounted() {
    this.$vuetify.goTo(0);

    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.REPORT]: {
        id: WorkflowActionIds.REPORT,
        text: `${this.$t("views.audit_reporting.report_download")}`,
        icon: "vertical_align_bottom",
        handler: () => {
          this.triggerDownloadPdf();
        },
      },
      [WorkflowActionIds.PREPARE_REPORT]: {
        id: WorkflowActionIds.PREPARE_REPORT,
        text: `${this.$t("views.audit_reporting.report_edit")}`,
        icon: "notes",
        handler: () => {
          console.log("Report bearbeiten");
          this.openReportingDialog();
          //alert(this.auditId);
        },
      },
      [WorkflowActionIds.ACTIONPLAN]: {
        id: WorkflowActionIds.ACTIONPLAN,
        text: `${this.$t("views.audit_reporting.actionplan_download")}`,
        icon: "notes",
        handler: () => {
          this.downloadXlsx(false);
        },
      },
    };

    const actionsList = this.actionsPerView("completed")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };

    this.$emit("actions", actions);
  }
}
